<template>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: 'Dashboard', path: '/dashboard', icon: 'ni ni-shop text-warning' }"/>
        <!-- <sidebar-item :link="{ name: 'Reports', path: '/reports', icon: 'fa fa-clipboard text-success' }"/> -->
         <sidebar-item
          :link="{ name: 'Today', icon: 'fa fa-clock text-primary', path: '/orders' }">
            <sidebar-item :link="{ name: 'Orders', path: '/orders/list' }"></sidebar-item>
            <sidebar-item
              :link="{ name: 'Report', path: '/orders/reports' }"
            >
            <sidebar-item
              :link="{ name: 'Driver Report', path: '/orders/reports/driverReport' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Customer Invoices', path: '/orders/reports/customerSettlements' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Pending Fee', path: '/orders/reports/customerDeliveryFee' }"
            ></sidebar-item>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'All Data', icon: 'fa fa-clock text-primary', path: '/all/orders' }">
            <sidebar-item :link="{ name: 'Orders', path: '/all/orders/list' }"></sidebar-item>
            <sidebar-item
              :link="{ name: 'Report', path: '/all/orders/reports' }"
            >
            <sidebar-item
              :link="{ name: 'Driver Report', path: '/all/orders/reports/driverReport' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Customer Invoices', path: '/all/orders/reports/customerSettlements' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Pending Fee', path: '/all/orders/reports/customerDeliveryFee' }"
            ></sidebar-item>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'Driver Salary', icon: 'fa fa-money-bill-alt text-success', path: '/drivers/salary' }">
            <sidebar-item :link="{ name: 'View Daily', path: '/drivers/salary/daily' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Week Salary', path: '/drivers/salary/weekly' }"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Customers', path: '/customers', icon: 'fa fa-users text-info' }"/>
        <sidebar-item :link="{ name: 'Drivers', path: '/drivers', icon: 'fa fa-motorcycle text-danger' }"/>
        <sidebar-item :link="{ name: 'Destinations', path: '/destinations', icon: 'fa fa-compass text-warning' }"/>
      </template>
    </side-bar>
</template>
<script>
export default {

}
</script>